import { openDB } from "idb";
import {stores} from "./stores";

// const stores=['COMMON','DB','STORIES','CALENDAR', 'VAL']

const dbPromise =
    typeof window !== "undefined"
        ? openDB("DBW", 1, {
              upgrade(db) {
                //   db.createObjectStore("COMMON");
                stores.forEach(store=>db.createObjectStore(store))
              },
          })
        : false;

export const getItem = async (key) => (await dbPromise).get("COMMON", key);

export const setItem = async (key, val) => (await dbPromise).put("COMMON", val, key);

export const delItem = async (key) => (await dbPromise).delete("COMMON", key);

export const clear = async () => (await dbPromise).clear("COMMON");

export const keys = async () => (await dbPromise).getAllKeys("COMMON");
