import React, { useEffect, useState } from "react";

/** Helpers */
import { appWithTranslation, useTranslation } from "next-i18next";
import PropTypes from "prop-types";

/** matomo */
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

/** emotion */
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

/** MUI */
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { StylesProvider, createGenerateClassName } from "@mui/styles";

/** NextJS */
import Head from "next/head";
import Router from "next/router";

/** Redux */
import { Provider } from "react-redux";

/** UI */
import store from "../src/_appStore/store";
import PagePreloader from "../src/_common/PagePreloader";
import App from "../src/layouts/_CommonLayout/App";
import theme from "../src/theme";

export const cache = createCache({ key: "odp", prepend: true });

const crypto = require("crypto"),
    shasum = crypto.createHash("sha1");
shasum.update("ODPlus");

const generateClassName = createGenerateClassName({
    productionPrefix: "odp--" + shasum.digest("hex").substr(0, 13),
    disableGlobal: true,
});

// const matomoInstance = createInstance({
//     urlBase: "https://stats.stat.gov.pl",
//     // srcUrl: "./js/matomo.js",
//     siteId: process.env.NEXT_PUBLIC_MATOMO_ID,
//     disabled: false,
//     heartBeat: {
//         active: true,
//         seconds: 10,
//     },
//     linkTracking: false,
//     configurations: {
//         disableCookies: true,
//         setSecureCookie: true,
//         setRequestMethod: "POST",
//     },
// });

const ODPApp = (props) => {
    const { Component, pageProps } = props;
    const { t } = useTranslation();

    /** States */
    const [pageLoading, setPageLoading] = useState(true);
    const [MATOMO_INSTANCE, setMATOMO_INSTANCE] = useState(null);

    /** Effects */
    useEffect(() => {
        const start = () => setPageLoading(true);
        const end = () => setPageLoading(false);

        Router.events.on("routeChangeStart", start);
        Router.events.on("routeChangeComplete", end);
        Router.events.on("routeChangeError", end);
        end();
        return () => {
            Router.events.off("routeChangeStart", start);
            Router.events.off("routeChangeComplete", end);
            Router.events.off("routeChangeError", end);
            end();
        };
    }, []);

    useEffect(() => {
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    useEffect(() => {
        const _matomoInstance = createInstance({
            urlBase: "https://stats.stat.gov.pl",
            // srcUrl: "./js/matomo.js",
            siteId: process.env.NEXT_PUBLIC_MATOMO_ID,
            disabled: window.location.hostname === "localhost",
            heartBeat: {
                active: true,
                seconds: 10,
            },
            linkTracking: false,
            configurations: {
                disableCookies: true,
                setSecureCookie: true,
                setRequestMethod: "POST",
            },
        });
        setMATOMO_INSTANCE(_matomoInstance)
    }, []);

    return (
        <React.Fragment>
            <CacheProvider value={cache}>
                <StylesProvider generateClassName={generateClassName}>
                    <Head>
                        {/* <title>Loading...</title> */}
                        <title>{t("Ładowanie")}</title>
                        <meta name="viewport" content="initial-scale=1, width=device-width" />
                    </Head>

                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <Provider store={store}>
                                {/* <MatomoProvider value={matomoInstance}> */}
                                <MatomoProvider value={MATOMO_INSTANCE}>
                                    {pageLoading ? (
                                        <PagePreloader />
                                    ) : (
                                        <>
                                            <App>
                                                <CssBaseline />
                                                <Component {...pageProps} />
                                            </App>
                                        </>
                                    )}
                                </MatomoProvider>
                            </Provider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </StylesProvider>
            </CacheProvider>
        </React.Fragment>
    );
};

export default appWithTranslation(ODPApp);

ODPApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};
