import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_DB";

export const DB_ListsTableSlice = createSlice({
    name: "DB_ListsTable",
    initialState: {
        selectedJT:null,
        selectedSC:null,
        selectedWSK:null,
    },
    reducers: {
        setSelectedJT: (state, action) => {
            typeof window !== "undefined" && setItem("LT.selectedJT", action.payload);
            state.selectedJT = action.payload;
        },
        setSelectedSC: (state, action) => {
            typeof window !== "undefined" && setItem("LT.selectedSC", action.payload);
            state.selectedSC = action.payload;
        },
        setSelectedWSK: (state, action) => {
            typeof window !== "undefined" && setItem("LT.selectedWSK", action.payload);
            state.selectedWSK = action.payload;
        },
    },
});

/** Clear all */
export const clear = () => (dispatch, getState) => {
    dispatch(setSelectedJT(null));
    dispatch(setSelectedSC(null));
    dispatch(setSelectedWSK(null));
};

export const { setSelectedJT, setSelectedSC, setSelectedWSK } = DB_ListsTableSlice.actions;

export const getSelectedJT = (state) => state.DB_ListsTable.selectedJT;
export const getSelectedSC = (state) => state.DB_ListsTable.selectedSC;
export const getSelectedWSK = (state) => state.DB_ListsTable.selectedWSK;

export default DB_ListsTableSlice.reducer;

export const fetchListsTableFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("LT.selectedJT").then((val) => dispatch(setSelectedJT(val || null)));
        getItem("LT.selectedSC").then((val) => dispatch(setSelectedSC(val || null)));
        getItem("LT.selectedWSK").then((val) => dispatch(setSelectedWSK(val || null)));
    }
};
