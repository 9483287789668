import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_CATALOG";

const DEFAULTS = {
    datesAndAmount: {
        date_from: "",
        date_to: "",
        amount: 0,
    },
};

export const CAT_CalculatorsSlice = createSlice({
    name: "CAT_Calculators",
    initialState: {
        _fetched: false,
        current: null,
        datesAndAmount: {
            date_from: "",
            date_to: "",
            amount: 0,
        },
        data: null,
        fields:null
    },
    reducers: {
        setFetched: (state, action) => {
            state._fetched = action.payload;
        },
        setCurrent: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.current", action.payload);
            state.current = action.payload;
        },
        setDatesAndAmount: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.datesAndAmount", action.payload);
            state.datesAndAmount = action.payload;
        },
        setCalculatorData: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.data", action.payload);
            state.data = action.payload;
        },
        setFields: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.fields", action.payload);
            state.fields = action.payload;
        },
    },
});

export const { setFetched, setCurrent, setDatesAndAmount, setCalculatorData, setFields } = CAT_CalculatorsSlice.actions;

export const getFetched = (state) => state.CAT_Calculators._fetched;
export const getCurrent = (state) => state.CAT_Calculators.current;
export const getDatesAndAmount = (state) => state.CAT_Calculators.datesAndAmount;
export const getDatesAndAmountDefault = (state) => DEFAULTS.datesAndAmount;
export const getCalculatorData = (state) => state.CAT_Calculators.data;
export const getFields = (state) => state.CAT_Calculators.fields;

export default CAT_CalculatorsSlice.reducer;

export const fetchCATCalculatorsFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("CAT.current").then((val) => dispatch(setCurrent(val || null)));
        getItem("CAT.datesAndAmount").then((val) => dispatch(setDatesAndAmount(val || DEFAULTS.datesAndAmount)));
        getItem("CAT.data").then((val) => dispatch(setCalculatorData(val || null)));
        getItem("CAT.fields").then((val) => dispatch(setFields(val || null)));
    }
};
