import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_DB";

export const DB_CommonSlice = createSlice({
    name: "DB_Common",
    initialState: {
        _fetched: false,
        leftDrawerOpen: false,
        leftDrawerWidth: 888,
        tableOrChart: "table",
        chartType: "Bars",
        isToApply: false,
        customerSection: null,
        customerDimension: null,
        customerPosition: null,
        limits: {
            table: {
                columns: 50,
                records: 3500000, // 35000,
                rows: 35000,
            },
            chart: {
                columns: 50,
                records: 10000, //50000,
                rows: 35000,
            },
        },
        prediction: { table: { _accepted: true }, chart: { _accepted: true } },
        predictionLoading: false,
        tableScrollTop: 0,
        areaForPanel: null,
        loadingModules: {
            dates: false,
            jt: false,
            tDefaults: false,
            tSections: false,
            tTypes: false,
            tDimensionsAndPositions: false,
            tResults: false,
        },
    },
    reducers: {
        setFetched: (state, action) => {
            state._fetched = action.payload;
        },
        setLeftDrawerOpen: (state, action) => {
            // typeof window !== "undefined" && localStorage.setItem("_.panel", action.payload);
            state.leftDrawerOpen = action.payload;
        },
        setAreaForPanel: (state, action) => {
            typeof window !== "undefined" && localStorage.setItem("_.area4panel", JSON.stringify(action.payload));
            state.areaForPanel = action.payload;
        },
        setLeftDrawerWidth: (state, action) => {
            state.leftDrawerWidth = action.payload;
        },
        setTableOrChart: (state, action) => {
            // typeof window !== "undefined" && setItem("_.TableOrChart", action.payload);
            typeof window !== "undefined" && localStorage.setItem("_.TableOrChart", action.payload);
            state.tableOrChart = action.payload;
        },
        setChartType: (state, action) => {
            // typeof window !== "undefined" && setItem("_.TableOrChart", action.payload);
            typeof window !== "undefined" && localStorage.setItem("_.ChartType", action.payload);
            state.chartType = action.payload;
        },
        setIsToApply: (state, action) => {
            state.isToApply = action.payload;
        },
        setCustomerSection: (state, action) => {
            state.customerSection = action.payload;
        },
        setCustomerDimension: (state, action) => {
            state.customerDimension = action.payload;
        },
        setCustomerPosition: (state, action) => {
            state.customerPosition = action.payload;
        },
        setPrediction: (state, action) => {
            state.prediction = action.payload || { table: { _accepted: true }, chart: { _accepted: true } };
        },
        setPredictionLoading: (state, action) => {
            state.predictionLoading = action.payload
        },
        setTableScrollTop: (state, action) => {
            state.tableScrollTop = action.payload;
        },
        setLoadingModules: (state, action) => {
            const { key, value } = action.payload;
            state.loadingModules[key] = value;
        },
    },
});

export const {
    setFetched,
    setLeftDrawerOpen,
    setLeftDrawerWidth,
    setTableOrChart,
    setIsToApply,
    setCustomerSection,
    setCustomerDimension,
    setCustomerPosition,
    setChartType,
    setPrediction,
    setPredictionLoading,
    setTableScrollTop,
    setAreaForPanel,
    setLoadingModules,
} = DB_CommonSlice.actions;

export const getFetched = (state) => state.DB_Common._fetched;
export const getLeftDrawerOpen = (state) => state.DB_Common.leftDrawerOpen;
export const getLeftDrawerWidth = (state) => state.DB_Common.leftDrawerWidth;
export const getTableOrChart = (state) => state.DB_Common.tableOrChart;
export const getIsToApply = (state) => state.DB_Common.isToApply;
export const getCustomerSection = (state) => state.DB_Common.customerSection;
export const getCustomerDimension = (state) => state.DB_Common.customerDimension;
export const getCustomerPosition = (state) => state.DB_Common.customerPosition;
export const getChartType = (state) => state.DB_Common.chartType;
export const getLimits = (state) => state.DB_Common.limits;
export const getPrediction = (state) => state.DB_Common.prediction;
export const getPredictionLoading = (state) => state.DB_Common.predictionLoading;
export const getTableScrollTop = (state) => state.DB_Common.tableScrollTop;
export const getAreaForPanel = (state) => state.DB_Common.areaForPanel;
export const getLoadingModules = (state) => state.DB_Common.loadingModules;

export default DB_CommonSlice.reducer;

export const fetchDBCommonFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        // getItem("_.TableOrChart").then((val) => dispatch(setTableOrChart(val || "table")));
        dispatch(setAreaForPanel(JSON.parse(localStorage.getItem("_.area4panel")) || null));
        dispatch(setTableOrChart(localStorage.getItem("_.TableOrChart") || "table"));
        dispatch(setChartType(localStorage.getItem("_.ChartType") || "Bars"));
    }
};
