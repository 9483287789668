import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_VAL";

export const VAL_CommonSlice = createSlice({
    name: "VAL_Common",
    initialState: {
        _fetched: false,
        leftDrawerOpen: true,
        area:null
    },
    reducers: {
        setFetched: (state, action) => {
            state._fetched = action.payload;
        },
        setLeftDrawerOpen: (state, action) => {
            state.leftDrawerOpen = action.payload;
        },
        setArea: (state, action) => {
            typeof window !== "undefined" && setItem("VAL.area", action.payload);
            state.area = action.payload;
        },
    },
});

export const { setFetched, setLeftDrawerOpen, setArea } = VAL_CommonSlice.actions;

export const getFetched = (state) => state.VAL_Common._fetched;
export const getLeftDrawerOpen = (state) => state.VAL_Common.leftDrawerOpen;
export const getArea = (state) => state.VAL_Common.area;

export default VAL_CommonSlice.reducer;

export const fetchVALCommonFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("VAL.area").then((val) => dispatch(setArea(val || null)));
    }
};
