import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_STORIES";

export const StorytellingSlice = createSlice({
    name: "Storytelling",
    initialState: {
        _fetched: false,
        mapClassifications: [],
        customization: [],
    },
    reducers: {
        setFetched: (state, action) => {
            state._fetched = action.payload;
        },
        setMapClassifications: (state, action) => {
            typeof window !== "undefined" && setItem("mapClassifications", action.payload);
            state.mapClassifications = action.payload;
        },
        setCustomization: (state, action) => {
            setItem("customization", action.payload);
            state.customization = action.payload;
        },
    },
});

export const { setFetched, setMapClassifications, setCustomization } = StorytellingSlice.actions;

export const getFetched = (state) => state.Storytelling._fetched;
export const getMapClassifications = (state) => state.Storytelling.mapClassifications;
export const getCustomization = (state) => state.Storytelling.customization;

export default StorytellingSlice.reducer;

export const fetchStorytellingFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("mapClassifications")
            .then((val) => dispatch(setMapClassifications(val || [])))
            .catch((e) => console.log(e));

        getItem("customization")
            .then((val) => dispatch(setCustomization(val || [])))
            .catch((e) => console.log(e));
    }
};
