import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_DB";

export const DB_Settings_Chart_BarsSlice = createSlice({
    name: "DB_Settings_Chart_Bars",
    initialState: {
        seriesColors: null,
        highlight: "#179B1B",
        markline: null,
        layout: { list: ["JT"], legend: ["Zm"], xAxis: ["SC"] },
        showHide: null,
        sorting: { open: false },
        chartTitle: "",
        defaults: {
            seriesColors: { custom: false, palettes: ["system", "teal"], custom_colors: null },
            highlight: "#179B1B",
            layout: { list: ["JT"], legend: ["Zm"], xAxis: ["SC"] },
            showHide: { labels: true, showNamesOrSymbols: true, showFullNames: true },
        },
    },
    reducers: {
        setSeriesColors: (state, action) => {
            typeof window !== "undefined" && setItem("SCB.seriesColors", action.payload);
            state.seriesColors = action.payload;
        },
        setHighlight: (state, action) => {
            typeof window !== "undefined" && setItem("SCB.highlight", action.payload);
            state.highlight = action.payload;
        },
        setMarkline: (state, action) => {
            typeof window !== "undefined" && setItem("SCB.markline", action.payload);
            state.markline = action.payload;
        },
        setLayout: (state, action) => {
            typeof window !== "undefined" && setItem("SCB.layout", action.payload);
            state.layout = action.payload;
        },
        setShowHide: (state, action) => {
            typeof window !== "undefined" && setItem("SCB.showHide", action.payload);
            state.showHide = action.payload;
        },
        setSorting: (state, action) => {
            typeof window !== "undefined" && setItem("SCB.sort", action.payload);
            state.sorting = action.payload;
        },
        setChartTitle: (state, action) => {
            typeof window !== "undefined" && setItem("SCB.chartTitle", action.payload);
            state.chartTitle = action.payload;
        },
    },
});

/** Clear all */
export const clear = () => (dispatch, getState) => {
    dispatch(setSeriesColors({ custom: false, palettes: ["system", "teal"], custom_colors: null }));
    dispatch(setHighlight("#179B1B"));
    dispatch(setMarkline(null));
    dispatch(setLayout({ list: ["JT"], legend: ["Zm"], xAxis: ["SC"] }));
    dispatch(setShowHide({ labels: true, showNamesOrSymbols: true, showFullNames: true }));
    dispatch(setSorting({ open: false }));
    dispatch(setChartTitle(""));
};

export const { setSeriesColors, setHighlight, setMarkline, setLayout, setShowHide, setSorting, setChartTitle } =
    DB_Settings_Chart_BarsSlice.actions;

export const getSeriesColors = (state) => state.DB_Settings_Chart_Bars.seriesColors;
export const getSeriesColorsDefault = (state) => state.DB_Settings_Chart_Bars.defaults.seriesColors;
export const getHighlight = (state) => state.DB_Settings_Chart_Bars.highlight;
export const getHighlightDefault = (state) => state.DB_Settings_Chart_Bars.defaults.highlight;
export const getMarkline = (state) => state.DB_Settings_Chart_Bars.markline;
export const getLayout = (state) => state.DB_Settings_Chart_Bars.layout;
export const getLayoutDefault = (state) => state.DB_Settings_Chart_Bars.defaults.layout;
export const getShowHide = (state) => state.DB_Settings_Chart_Bars.showHide;
export const getShowHideDefault = (state) => state.DB_Settings_Chart_Bars.defaults.showHide;
export const getSorting = (state) => state.DB_Settings_Chart_Bars.sorting;
export const getChartTitle = (state) => state.DB_Settings_Chart_Bars.chartTitle;

export default DB_Settings_Chart_BarsSlice.reducer;

export const fetchSettingsChartBarsFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("SCB.seriesColors").then((val) =>
            dispatch(setSeriesColors(val || { custom: false, palettes: ["system", "teal"], custom_colors: null }))
        );
        getItem("SCB.highlight").then((val) => dispatch(setHighlight(val || "#179B1B")));
        getItem("SCB.markline").then((val) => dispatch(setMarkline(val || null)));
        getItem("SCB.layout").then((val) =>
            dispatch(setLayout(val || { list: ["JT"], legend: ["Zm"], xAxis: ["SC"] }))
        );
        getItem("SCB.showHide").then((val) =>
            dispatch(setShowHide(val || { labels: true, showNamesOrSymbols: true, showFullNames: true }))
        );
        getItem("SCB.sort").then((val) => dispatch(setSorting(val || { open: false })));
        getItem("SCB.chartTitle").then((val) => dispatch(setChartTitle(val || "")));
    }
};
