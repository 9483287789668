import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_CATALOG";

export const CAT_AggregatesSlice = createSlice({
    name: "CAT_Aggregates",
    initialState: {
        _fetched: false,
        allIndicators: [],
        selectedIndicators: [],
        currentIndicator: null,
        setIndicators: [],
        expanded: [],
        scrolled: null,
        leftDrawerOpen: true,
        leftDrawerWidth: 888,
    },
    reducers: {
        setFetched: (state, action) => {
            state._fetched = action.payload;
        },
        setAllIndicators: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.wsk.all", action.payload);
            state.allIndicators = action.payload;
        },
        setSelectedIndicators: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.wsk.selected", action.payload);
            state.selectedIndicators = action.payload;
        },
        setCurrentIndicator: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.wsk.current", action.payload);
            state.currentIndicator = action.payload;
        },
        setSetIndicators: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.wsk.set", action.payload);
            state.setIndicators = action.payload;
        },
        setExpanded: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.wsk.expanded", action.payload);
            state.expanded = action.payload;
        },
        setScrolled: (state, action) => {
            state.scrolled = action.payload;
        },
        setLeftDrawerOpen: (state, action) => {
            state.leftDrawerOpen = action.payload;
        },
        setLeftDrawerWidth: (state, action) => {
            state.leftDrawerWidth = action.payload;
        },
    },
});

export const {
    setFetched,
    setAllIndicators,
    setSelectedIndicators,
    setCurrentIndicator,
    setSetIndicators,
    setExpanded,
    setScrolled,
    setLeftDrawerOpen,
    setLeftDrawerWidth
} = CAT_AggregatesSlice.actions;

export const getFetched = (state) => state.CAT_Aggregates._fetched;
export const getAllIndicators = (state) => state.CAT_Aggregates.allIndicators;
export const getSelectedIndicators = (state) => state.CAT_Aggregates.selectedIndicators;
export const getCurrentIndicator = (state) => state.CAT_Aggregates.currentIndicator;
export const getSetIndicators = (state) => state.CAT_Aggregates.setIndicators;
export const getExpanded = (state) => state.CAT_Aggregates.expanded;
export const getScrolled = (state) => state.CAT_Aggregates.scrolled;
export const getLeftDrawerOpen = (state) => state.CAT_Aggregates.leftDrawerOpen;
export const getLeftDrawerWidth = (state) => state.CAT_Aggregates.leftDrawerWidth;

export default CAT_AggregatesSlice.reducer;

export const fetchAggregatesFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        await getItem("CAT.wsk.all").then((val) => dispatch(setAllIndicators(val || [])));

        await getItem("CAT.wsk.selected").then((val) => {
            const _newVal = [val || []].flatMap((v) => v);

            dispatch(setSelectedIndicators(_newVal));
        });

        await getItem("CAT.wsk.current").then((val) => dispatch(setCurrentIndicator(val || null)));

        await getItem("CAT.wsk.set").then((val) => dispatch(setSetIndicators(val || [])));
        await getItem("CAT.wsk.expanded").then((val) => dispatch(setExpanded(val || [])));
    }
};
