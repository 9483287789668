import { openDB } from "idb";
import {stores} from "./stores";

// const stores = ["COMMON", "DB", "STORIES", "CALENDAR", "VAL"];

const dbPromise =
    typeof window !== "undefined"
        ? openDB("DBW", 1, {
              upgrade(db) {
                  //   db.createObjectStore("DB");
                  stores.forEach((store) => db.createObjectStore(store));
              },
          })
        : false;

export const getItem = async (key) => (await dbPromise).get("DB", key);

export const setItem = async (key, val) => (await dbPromise).put("DB", val, key);

export const delItem = async (key) => (await dbPromise).delete("DB", key);

export const clear = async () => (await dbPromise).clear("DB");

export const keys = async () => (await dbPromise).getAllKeys("DB");
