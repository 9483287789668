import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_DB";

export const DB_IndicatorsSlice = createSlice({
    name: "DB_Indicators",
    initialState: {
        allIndicators: [],
        selectedIndicators: [],
        currentIndicator: null,
        setIndicators: [],
        expanded: [],
        scrolled: null,
    },
    reducers: {
        setAllIndicators: (state, action) => {
            typeof window !== "undefined" && setItem("WSK.all", action.payload);
            state.allIndicators = action.payload;
        },
        setSelectedIndicators: (state, action) => {
            typeof window !== "undefined" && setItem("WSK.selected", action.payload);
            state.selectedIndicators = action.payload;
        },
        setCurrentIndicator: (state, action) => {
            typeof window !== "undefined" && setItem("WSK.current", action.payload);
            state.currentIndicator = action.payload;
        },
        setSetIndicators: (state, action) => {
            typeof window !== "undefined" && setItem("WSK.set", action.payload);
            state.setIndicators = action.payload;
        },
        setExpanded: (state, action) => {
            typeof window !== "undefined" && setItem("WSK.expanded", action.payload);
            state.expanded = action.payload;
        },
        setScrolled: (state, action) => {
            state.scrolled = action.payload;
        },
    },
});

export const {
    setAllIndicators,
    setSelectedIndicators,
    setCurrentIndicator,
    setSetIndicators,
    setExpanded,
    setScrolled,
} = DB_IndicatorsSlice.actions;

export const getAllIndicators = (state) => state.DB_Indicators.allIndicators;
export const getSelectedIndicators = (state) => state.DB_Indicators.selectedIndicators;
export const getCurrentIndicator = (state) => state.DB_Indicators.currentIndicator;
export const getSetIndicators = (state) => state.DB_Indicators.setIndicators;
export const getExpanded = (state) => state.DB_Indicators.expanded;
export const getScrolled = (state) => state.DB_Indicators.scrolled;

export default DB_IndicatorsSlice.reducer;

export const fetchWSKFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        await getItem("WSK.all").then((val) => dispatch(setAllIndicators(val || [])));

        await getItem("WSK.selected").then((val) => {
            const _newVal = [val || []].flatMap((v) => v);

            dispatch(setSelectedIndicators(_newVal));
        });

        await getItem("WSK.current").then((val) => dispatch(setCurrentIndicator(val || null)));

        await getItem("WSK.set").then((val) => dispatch(setSetIndicators(val || [])));
        await getItem("WSK.expanded").then((val) => dispatch(setExpanded(val || [])));
    }
};
