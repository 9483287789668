import { createTheme } from "@mui/material/styles";
import { deepPurple, teal } from "@mui/material/colors";
// console.log({deepPurple, teal})

const primaryColors = deepPurple;
const secondaryColors = teal;

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#744FAC",
            colors: primaryColors,
        },
        secondary: {
            main: "#00818A",
            colors: secondaryColors,
        },
    },
    typography: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontWeight: 300,
            fontSize: "6rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
        },
        h2: {
            fontWeight: 300,
            fontSize: "3.75rem",
            lineHeight: 1.2,
            letterSpacing: "-0.00833em",
        },
        h3: {
            fontWeight: 300,
            fontSize: "3rem",
            lineHeight: 1.167,
            letterSpacing: "0em",
        },
        h4: {
            fontWeight: 300,
            fontSize: "2.125rem",
            lineHeight: 1.235,
            letterSpacing: "0.00735em",
        },
        h5: {
            fontWeight: 300,
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
        },
        h6: {
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em",
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em",
        },
        body1: {
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
        },
        body2: {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
        },
        button: {
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em",
            textTransform: "uppercase",
        },
        caption: {
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
        },
        overline: {
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase",
        },
    },
});

// console.log(defaultTheme.palette)

const { breakpoints } = defaultTheme;

const outline = {
    outlineOffset: 2, //3,
    outlineColor: "#101010",
    // outlineStyle: "solid",
    outlineStyle: "auto",
    outlineWidth: 2,
};

const theme = {
    ...defaultTheme,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    // fontSize: "max(0.9375vw, 14px)!important",
                    fontSize: "max(0.9375vw, 18px)!important",
                    "@media (max-width: 899.95px)": {
                        fontSize: "18px!important",
                    },
                    WebkitFontSmoothing: "auto",
                    minHeight: "100vh",
                    padding: 0,
                    margin: 0,
                },
                body: {
                    fontFamily: "'Open Sans', sans-serif",
                    padding: 0,
                    margin: 0,
                    fontSize: "1rem",
                    fontWeight: 400,
                    backgroundColor: "#F8F9FA",
                    /* if device has a touch screen */
                    "@media (any-pointer: coarse) and (max-width: 1365.99px)": {
                        // border: "1px solid #F00",
                        "& *": {
                            whiteSpace: "wrap!important",
                            // "-webkit-line-clamp": "unset!important",
                            lineClamp: "unset!important",                            
                        },
                    },
                    /* if device has no touch screen */
                    "@media (any-pointer: fine)": {
                        // border: "1px solid #0F0",
                    },
                    "& .CtxtMenu_MenuFrame": {
                        zIndex: "2000!important",
                    },
                },
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "#CCC",
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&:focus-visible, &.Mui-focusVisible": {
                        ...outline,
                        outlineOffset: -2,
                    },
                },
            },
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 26,
                    fontSize: "0.777777rem",
                    fontWeight: 600,
                    padding: defaultTheme.spacing(2, 3.25),
                    height: 51,
                    letterSpacing: "0.0694444444rem",
                    "&:focus-visible, &.Mui-focusVisible": {
                        ...outline,
                        outlineOffset: 3,
                    },
                },
                contained: {
                    padding: defaultTheme.spacing(1.75, 3.25),
                    "&.Mui-disabled": {
                        color: "#AAA",
                    },
                },
                containedSizeSmall: {
                    padding: defaultTheme.spacing(1, 2),
                    fontSize: "80%",
                },
                outlined: {
                    padding: defaultTheme.spacing(1.75, 3.25),
                    borderColor: defaultTheme.palette.primary.main,
                },
                outlinedSizeSmall: {
                    padding: defaultTheme.spacing(1, 2),
                    fontSize: "80%",
                    borderColor: defaultTheme.palette.primary.main,
                },
                "&:focus-visible": {
                    ...outline,
                },
            },
        },
        Checkbox: {
            styleOverrides: {
                root: {
                    "&:focus-visible": {
                        ...outline,
                        outlineOffset: -2,
                    },
                },
            },
        },

        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: "#757575",
                },
                track: {
                    opacity: 0.15,
                },
            },
        },
    },
    shape: {
        ...defaultTheme.shape,
        boxRadius: 20,
        boxedWrapper: {
            backgroundColor: "#FFF",
            boxShadow: "3px 3px 15px #0000000D",
            padding: defaultTheme.spacing(2, 2),
            borderRadius: 20,
            border: "0.5px solid #D2D6DA",
            [defaultTheme.breakpoints.down("xs")]: {
                padding: defaultTheme.spacing(1, 2),
                borderRadius: 20,
            },
        },
    },
    charts: {
        palette: {
            primaryStart: "#744FAC",
            primaryEnd: "#E9E2F2",
            primary: ["#744FAC", "#8767B7", "#9A80C3", "#AE98CF", "#C2B1DA", "#D5CAE6", "#E9E2F2"],

            primaryDarkerStart: "#5b3f87",
            primaryDarkerEnd: "#D5CAE6",
            primaryDarker: ["#5b3f87", "#744FAC", "#8767B7", "#9A80C3", "#AE98CF", "#C2B1DA", "#D5CAE6"],

            extendedStart: "#744FAC",
            extendedEnd: "#C2B1DA", //"#E3DCEE",
            extendedDarkerStart: "#5b3f87",
            extendedDarkerEnd: "#C2B1DA", //"#DCD3EA",
            extended: [
                "#744FAC",
                "#7B57B0",
                "#8160B4",
                "#8869B8",
                "#8F72BC",
                "#977BC1",
                "#9D83C4",
                "#A48CC9",
                "#AB95CD",
                "#B29ED1",
                "#B9A6D5",
                "#C0AFD9",
                "#C7B8DE",
                "#CEC1E2",
                "#D5CAE6",
                "#DCD3EA",
                "#E3DCEE",
            ],
        },
        fontSize: 16,
        color: "#000000de",
    },
    outline,
    outliness:{
        outlineOffset: 2,
        outlineWidth: 2,
        outlineColor: defaultTheme.palette.primary.main,
        outlineStyle: "dotted",
    }
};

// console.log({ theme })

export default theme;
