import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_DB";

const DEFAULTS = {
    layout: { list: [], rows: ["Zm", "JT"], cols: ["SC"] },
    options: { blockHead: true, showSymbols: false, showEmptyRows: true, showEmptyCols: true },
    sorting: [],
    pageLength: 10,
};

export const DB_SettingsTableSlice = createSlice({
    name: "DB_SettingsTable",
    initialState: {
        layout: DEFAULTS.layout,
        layoutDefault: DEFAULTS.layout,
        options: DEFAULTS.options,
        optionsDefault: DEFAULTS.options,
        sorting: DEFAULTS.sorting,
        sortingDefault: DEFAULTS.sorting,
        pageLength: DEFAULTS.pageLength,
    },
    reducers: {
        setLayout: (state, action) => {
            typeof window !== "undefined" && setItem("ST.layout", action.payload);
            state.layout = action.payload;
        },
        setOptions: (state, action) => {
            typeof window !== "undefined" && setItem("ST.options", action.payload);
            state.options = action.payload;
        },
        setSorting: (state, action) => {
            typeof window !== "undefined" && setItem("ST.sort", action.payload);
            state.sorting = action.payload;
        },
        setPageLength: (state, action) => {
            typeof window !== "undefined" && setItem("ST.pageLen", action.payload);
            state.pageLength = action.payload;
        },
    },
});

/** Clear all */
export const clear = () => (dispatch, getState) => {
    // dispatch(setLayout(null));
    // dispatch(setOptions(null));
    // dispatch(setSorting(null));
    // dispatch(setPageLength(10));
    dispatch(setLayout(DEFAULTS.layout));
    dispatch(setOptions(DEFAULTS.options));
    dispatch(setSorting(DEFAULTS.sorting));
    dispatch(setPageLength(DEFAULTS.pageLength));
};

export const { setLayout, setOptions, setSorting, setPageLength } = DB_SettingsTableSlice.actions;

export const getLayout = (state) => state.DB_SettingsTable.layout;
export const getLayoutDefault = (state) => state.DB_SettingsTable.layoutDefault;
export const getOptions = (state) => state.DB_SettingsTable.options;
export const getOptionsDefault = (state) => state.DB_SettingsTable.optionsDefault;
export const getPageLength = (state) => state.DB_SettingsTable.pageLength;
export const getSorting = (state) => state.DB_SettingsTable.sorting;
export const getSortingDefault = (state) => state.DB_SettingsTable.sortingDefault;

export default DB_SettingsTableSlice.reducer;

export const fetchSettingsTableFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("ST.layout").then((val) => dispatch(setLayout(val || DEFAULTS.layout)));

        getItem("ST.options").then((val) => dispatch(setOptions(val || DEFAULTS.options)));

        getItem("ST.sort").then((val) => dispatch(setSorting(val || DEFAULTS.sorting)));

        getItem("ST.pageLen").then((val) => dispatch(setPageLength(val || DEFAULTS.pageLength)));
    }
};
