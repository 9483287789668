import { openDB } from "idb";
import {stores} from "./stores";

const dbPromise =
    typeof window !== "undefined"
        ? openDB("DBW", 1, {
              upgrade(db) {
                  stores.forEach((store) => db.createObjectStore(store));
              },
          })
        : false;

export const getItem = async (key) => (await dbPromise).get("CATALOG", key);

export const setItem = async (key, val) => (await dbPromise).put("CATALOG", val, key);

export const delItem = async (key) => (await dbPromise).delete("CATALOG", key);

export const clear = async () => (await dbPromise).clear("CATALOG");

export const keys = async () => (await dbPromise).getAllKeys("CATALOG");
