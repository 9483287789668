import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_DB";

export const DB_FiltersChartsSlice = createSlice({
    name: "DB_FiltersCharts",
    initialState: {
        sections: null,
        currentSection: null,
        multiSections: { enabled: false },
        infoTypes: null,
        dimensions: null,
        defaults:null
    },
    reducers: {
        setSections: (state, action) => {
            typeof window !== "undefined" && setItem("FC.sections", action.payload);
            state.sections = action.payload;
        },
        setCurrentSection: (state, action) => {
            typeof window !== "undefined" && setItem("FC.currentSection", action.payload);
            state.currentSection = action.payload;
        },
        setMultiSections: (state, action) => {
            typeof window !== "undefined" && setItem("FC.multiSections", action.payload);
            state.multiSections = action.payload;
        },
        setInfoTypes: (state, action) => {
            typeof window !== "undefined" && setItem("FC.infoTypes", action.payload);
            state.infoTypes = action.payload;
        },
        setDimensions: (state, action) => {
            typeof window !== "undefined" && setItem("FC.dimensions", action.payload);
            state.dimensions = action.payload;
        },
        setDefaults: (state, action) => {
            typeof window !== "undefined" && setItem("FC.defaults", action.payload);
            state.defaults = action.payload;
        },
    },
});

/** Clear all */
export const clear = () => (dispatch, getState) => {
    dispatch(setSections(null));
    dispatch(setCurrentSection(null));
    dispatch(setMultiSections({ enabled: false }));
    dispatch(setInfoTypes(null));
    dispatch(setDimensions(null));
    dispatch(setDefaults(null));
};

export const { setSections, setCurrentSection, setMultiSections, setInfoTypes, setDimensions, setDefaults } =
    DB_FiltersChartsSlice.actions;

export const getSections = (state) => state.DB_FiltersCharts.sections;
export const getCurrentSection = (state) => state.DB_FiltersCharts.currentSection;
export const getMultiSections = (state) => state.DB_FiltersCharts.multiSections;
export const getInfoTypes = (state) => state.DB_FiltersCharts.infoTypes;
export const getDimensions = (state) => state.DB_FiltersCharts.dimensions;
export const getDefaults = (state) => state.DB_FiltersCharts.defaults;

export default DB_FiltersChartsSlice.reducer;

export const fetchChartsFiltersFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("FC.sections").then((val) => dispatch(setSections(val || null)));
        getItem("FC.currentSection").then((val) => dispatch(setCurrentSection(val || null)));
        getItem("FC.multiSections").then((val) => dispatch(setMultiSections(val || { enabled: false })));
        getItem("FC.infoTypes").then((val) => dispatch(setInfoTypes(val || null)));
        getItem("FC.dimensions").then((val) => dispatch(setDimensions(val || null)));
        getItem("FC.defaults").then((val) => dispatch(setDefaults(val || null)));
    }
};
