import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_DB";

export const DB_JTsSlice = createSlice({
    name: "DB_JTs",
    initialState: {
        territorialUnits: [],
        selectedTerritorialUnitsItems: [],
        selectedTerritorialUnitsItemsDefault: [],
        territorialUnitsType: "KTS",
        expanded: ["33617"],
        scrolled: null,
        simpleTree: false,
        largePanel: {
            open: false,
            tabIndex: 0,
        },
        forIndicators: [],
        customerJTs:[]
    },
    reducers: {
        setTerritorialUnits: (state, action) => {
            typeof window !== "undefined" && setItem("JT.all", action.payload);
            state.territorialUnits = action.payload;
        },
        setSelectedTerritorialUnitsItem: (state, action) => {
            typeof window !== "undefined" && setItem("JT.selected", action.payload);
            state.selectedTerritorialUnitsItems = action.payload;
        },
        setSelectedTerritorialUnitsItemDefault: (state, action) => {
            typeof window !== "undefined" && setItem("JT.selectedDefault", action.payload);
            state.selectedTerritorialUnitsItemsDefault = action.payload;
        },
        setTerritorialUnitsType: (state, action) => {
            typeof window !== "undefined" && setItem("JT.type", action.payload);
            state.territorialUnitsType = action.payload;
        },
        setExpanded: (state, action) => {
            typeof window !== "undefined" && setItem("JT.expanded", action.payload);
            state.expanded = action.payload;
        },
        setScrolled: (state, action) => {
            state.scrolled = action.payload;
        },
        setSimpleTree: (state, action) => {
            state.simpleTree = action.payload;
        },
        setLargePanel: (state, action) => {
            state.largePanel = action.payload;
        },
        setJTsForIndicators: (state, action) => {
            typeof window !== "undefined" && setItem("JT.forWSKs", action.payload);
            state.forIndicators = action.payload;
        },        
        setCustomerJTs: (state, action) => {
            state.customerJTs = action.payload;
        },
    },
});

export const {
    setSelectedTerritorialUnitsItem,
    setSelectedTerritorialUnitsItemDefault,
    setTerritorialUnitsType,
    setTerritorialUnits,
    setExpanded,
    setScrolled,
    setSimpleTree,
    setLargePanel,
    setJTsForIndicators,
    setCustomerJTs
} = DB_JTsSlice.actions;

export const getSelectedTerritorialUnitsItems = (state) => state.DB_JTs.selectedTerritorialUnitsItems;
export const getSelectedTerritorialUnitsItemDefault = (state) => state.DB_JTs.selectedTerritorialUnitsItemsDefault;
export const getTerritorialUnitsType = (state) => state.DB_JTs.territorialUnitsType;
export const getTerritorialUnits = (state) => state.DB_JTs.territorialUnits;
export const getExpanded = (state) => state.DB_JTs.expanded;
export const getScrolled = (state) => state.DB_JTs.scrolled;
export const getSimpleTree = (state) => state.DB_JTs.simpleTree;
export const getLargePanel = (state) => state.DB_JTs.largePanel;
export const getJTsForIndicators = (state) => state.DB_JTs.forIndicators;
export const getCustomerJTs = (state) => state.DB_JTs.customerJTs;

export default DB_JTsSlice.reducer;

export const fetchJTFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("JT.all").then((val) => dispatch(setTerritorialUnits(val || []))).catch(e=>console.log(e));
        getItem("JT.selected").then((val) => dispatch(setSelectedTerritorialUnitsItem(val || [])));
        getItem("JT.selectedDefault").then((val) => dispatch(setSelectedTerritorialUnitsItemDefault(val || null)));
        getItem("JT.type").then((val) => dispatch(setTerritorialUnitsType(val || "KTS")));
        getItem("JT.expanded").then((val) => dispatch(setExpanded(val || ["33617"])));
        getItem("JT.forWSKs").then((val) => dispatch(setJTsForIndicators(val || [])));
    }
};
