import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_DB";

export const DB_DatesSlice = createSlice({
    name: "DB_Dates",
    initialState: {
        years: [],
        definitions: [],
        definitionsDefault: [],
        data: [],
        selected: [],
        selectedDefault: [],
        expanded: ["0"],
        scrolled: null,
        simpleTree: false,
        largePanel: {
            open: false,
            tabIndex: 0,
        },
        forIndicators:
            typeof window !== "undefined" && localStorage.getItem("dates.4WSKs")
                ? JSON.parse(localStorage.getItem("dates.4WSKs"))
                : [],
    },
    reducers: {
        setDatesYears: (state, action) => {
            typeof window !== "undefined" && setItem("DATES.years", action.payload);
            state.years = action.payload;
        },
        setDatesDefinitions: (state, action) => {
            typeof window !== "undefined" && setItem("DATES.defs", action.payload);
            state.definitions = action.payload;
        },
        setDatesDefinitionsDefault: (state, action) => {
            typeof window !== "undefined" && setItem("DATES.defsDefault", action.payload);
            state.definitionsDefault = action.payload;
        },
        setDatesTreeData: (state, action) => {
            typeof window !== "undefined" && setItem("DATES.data", action.payload);
            state.data = action.payload;
        },
        setDatesSelected: (state, action) => {
            typeof window !== "undefined" && setItem("DATES.sels", action.payload);
            state.selected = action.payload;
        },
        setDatesSelectedDefault: (state, action) => {
            typeof window !== "undefined" && setItem("DATES.selsDefault", action.payload);
            state.selectedDefault = action.payload;
        },
        setDatesExpanded: (state, action) => {
            state.expanded = action.payload;
        },
        setDatesScrolled: (state, action) => {
            state.scrolled = action.payload;
        },
        setDatesSimpleTree: (state, action) => {
            state.simpleTree = action.payload;
        },
        setDatesLargePanel: (state, action) => {
            state.largePanel = action.payload;
        },
        setDatesForIndicators: (state, action) => {
            typeof window !== "undefined" && setItem("DATES.4WSKs", action.payload);
            state.forIndicators = action.payload;
        },
    },
});

export const {
    setDatesYears,
    setDatesDefinitions,
    setDatesDefinitionsDefault,
    setDatesTreeData,
    setDatesSelected,
    setDatesSelectedDefault,
    setDatesExpanded,
    setDatesScrolled,
    setDatesSimpleTree,
    setDatesLargePanel,
    setDatesForIndicators,
} = DB_DatesSlice.actions;

export const getDatesYears = (state) => state.DB_Dates.years;
export const getDatesDefinitions = (state) => state.DB_Dates.definitions;
export const getDatesDefinitionsDefault = (state) => state.DB_Dates.definitionsDefault;
export const getDatesTreeData = (state) => state.DB_Dates.data;
export const getDatesSelected = (state) => state.DB_Dates.selected;
export const getDatesSelectedDefault = (state) => state.DB_Dates.selectedDefault;
export const getDatesExpanded = (state) => state.DB_Dates.expanded;
export const getDatesScrolled = (state) => state.DB_Dates.scrolled;
export const getDatesSimpleTree = (state) => state.DB_Dates.simpleTree;
export const getDatesLargePanel = (state) => state.DB_Dates.largePanel;
export const getDatesForIndicators = (state) => state.DB_Dates.forIndicators;

export default DB_DatesSlice.reducer;

export const fetchDATESFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("DATES.years")
            .then((val) => dispatch(setDatesYears(val || [])))
            .catch((e) => console.log(e));
        getItem("DATES.defs").then((val) => dispatch(setDatesDefinitions(val || [])));
        getItem("DATES.defsDefault").then((val) => dispatch(setDatesDefinitionsDefault(val || [])));
        getItem("DATES.data").then((val) => dispatch(setDatesTreeData(val || [])));
        getItem("DATES.sels").then((val) => dispatch(setDatesSelected(val || [])));
        getItem("DATES.selsDefault").then((val) => dispatch(setDatesSelectedDefault(val || [])));
        getItem("DATES.4WSKs").then((val) => dispatch(setDatesForIndicators(val || [])));
    }
};

/** Clear all */
export const clear = () => (dispatch, getState) => {
    dispatch(setDatesYears([]));
    dispatch(setDatesDefinitions([]));
    dispatch(setDatesDefinitionsDefault([]));
    dispatch(setDatesTreeData([]));
    dispatch(setDatesSelected([]));
    dispatch(setDatesSelectedDefault([]));
    dispatch(setDatesForIndicators([]));
};
