import React from "react";
import { useTranslation } from "react-i18next";

const MainLogoPl = "/images/logo_DBW.svg";
const MainLogoEn = "/images/logo_DBW_eng.svg";

const PagePreloader = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div
                style={{
                    width: '75vw',
                    maxWidth: 600,
                    height: 400,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    background: "transparent",
                    filter: "blur(0px)",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 99999,
                }}
            >
                <img
                    src={i18n === "en" ? MainLogoEn : MainLogoPl}
                    alt="GUS DBW"
                    width={477.82}
                    // height={64}
                    style={{ width: "100%", maxWidth: 404, transform: 'translateY(-50%)' }}
                />
                <img src="/images/preloader_CircleV01.gif" style={{ opacity: 1, display: "block" }} alt="loading..." />
                <div
                    style={{
                        display: "block",
                        textAlign: "center",
                        width: "100%",
                        fontSize: "1.5rem",
                        fontWeight: 400,
                        color: "#744FAC",
                    }}
                >
                    <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "1rem", color:'#020203' }}>
                        {t("Ładowanie")}
                        {"..."}
                    </p>
                </div>
            </div>
        </>
    );
};

export default PagePreloader;
