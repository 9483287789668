import { createSlice } from "@reduxjs/toolkit";
import {getItem, setItem } from "./utils/_idb_COMMON";

export const AreasSlice = createSlice({
    name: "Areas",
    initialState: {
        areasList:[],
        selectedAreasItems:[],
        forIndicators:[],
    },
    reducers: {
        setAreasList: (state, action) => {
            typeof window !== "undefined" && setItem("areas.list", action.payload);
            state.areasList = action.payload;
        },
        setSelectedAreasItems: (state, action) => {
            typeof window !== "undefined" && setItem("areas.sels", action.payload);
            state.selectedAreasItems = action.payload;
        },
        setAreasForIndicators: (state, action) => {
            typeof window !== "undefined" && setItem("areas.4WSKs", action.payload);
            state.forIndicators = action.payload;
        },
    },
});

export const { setAreasList, setSelectedAreasItems, setAreasForIndicators } = AreasSlice.actions;
export const getAreasList = (state) => state.Areas.areasList;
export const getSelectedAreasItems = (state) => state.Areas.selectedAreasItems;
export const getAreasForIndicators = (state) => state.Areas.forIndicators;

export default AreasSlice.reducer;

export const fetchAreasFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("areas.list").then((val) => dispatch(setAreasList(val || []))).catch(e=>console.log(e));
        getItem("areas.sels").then((val) => dispatch(setSelectedAreasItems(val || []))).catch(e=>console.log(e));
        getItem("areas.4WSKs").then((val) => dispatch(setAreasForIndicators(val || []))).catch(e=>console.log(e));
    }
};