import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_COMMON";

export const CommonSlice = createSlice({
    name: "Common",
    initialState: {
        _fetched: false,
        leftDrawerOpened: true,
        leftDrawerWidth: 688,
        maxWidth: 1920,
        expandedAreas: [],
        askDeleteFromCart: false,
        topMenu: [],
        appFontSize: null,
        fullTopMenu: null,
        appFilters: null,
        scroll: 0,
        advancedJT: false,
        advancedStoryMaps: false,
        is1920: false,
        showToolbox: false,
        showExperimentalShare: false,
        showIDs: false,
        splitLines: true,
        splitLinesExtra: true,
        showAbortDB: true,
        paletteScheme: "mono",
        customizeChart: false,
        showGotoDB: true,
        dbPositionsTree: true,
        showDbPositionsTreeSwitch: false,
        dashboardTOC: false,
        DbPageVer: 1,
        showDbStatusBar: false,
        skipLinksPage: "",
        dbSettingsDnD: true,
    },
    reducers: {
        setFetched: (state, action) => {
            state._fetched = action.payload;
        },
        setLeftDrawerOpened: (state, action) => {
            state.leftDrawerOpened = action.payload;
        },
        setLeftDrawerWidth: (state, action) => {
            state.leftDrawerWidth = action.payload;
        },
        setExpandedAreas: (state, action) => {
            state.expandedAreas = action.payload;
        },
        setAskDeleteFromCart: (state, action) => {
            state.askDeleteFromCart = action.payload;
        },
        setTopMenu: (state, action) => {
            typeof window !== "undefined" && setItem("TopMenu", JSON.stringify(action.payload));
            state.topMenu = action.payload;
        },
        setAppFontSize: (state, action) => {
            setItem("AppFontSize", action.payload);
            state.appFontSize = action.payload;
        },
        setFullTopMenu: (state, action) => {
            setItem("fullTM", action.payload);
            state.fullTopMenu = action.payload;
        },
        setAppFilters: (state, action) => {
            setItem("filters", action.payload);
            state.appFilters = action.payload;
        },
        setScroll: (state, action) => {
            state.scroll = action.payload;
        },
        setAdvancedJT: (state, action) => {
            setItem("advJT", action.payload);
            state.advancedJT = action.payload;
        },
        setAdvancedStoryMaps: (state, action) => {
            setItem("advStM", action.payload);
            state.advancedStoryMaps = action.payload;
        },
        setIs1920: (state, action) => {
            state.is1920 = action.payload;
        },
        setShowToolbox: (state, action) => {
            state.showToolbox = action.payload;
        },
        setShowExperimentalShare: (state, action) => {
            setItem("expS", action.payload);
            state.showExperimentalShare = action.payload;
        },
        setShowIDs: (state, action) => {
            setItem("showIDs", action.payload);
            state.showIDs = action.payload;
        },
        setSplitLines: (state, action) => {
            setItem("splitLines", action.payload);
            state.splitLines = action.payload;
        },
        setSplitLinesExtra: (state, action) => {
            setItem("splitLinesExtra", action.payload);
            state.splitLinesExtra = action.payload;
        },
        setShowAbortDB: (state, action) => {
            setItem("showAbortDB", action.payload);
            state.showAbortDB = action.payload;
        },
        setPaletteScheme: (state, action) => {
            setItem("paletteScheme", action.payload);
            state.paletteScheme = action.payload;
        },
        setCustomizeChart: (state, action) => {
            setItem("customizeChart", action.payload);
            state.customizeChart = action.payload;
        },
        setShowGotoDB: (state, action) => {
            setItem("showGototDB", action.payload);
            state.showGotoDB = action.payload;
        },
        setDbPositionsTree: (state, action) => {
            setItem("dbPositionsTree", action.payload);
            state.dbPositionsTree = action.payload;
        },
        setShowDbPositionsTreeSwitch: (state, action) => {
            setItem("dbPositionsTreeSwitch", action.payload);
            state.showDbPositionsTreeSwitch = action.payload;
        },
        setDashboardTOC: (state, action) => {
            setItem("dashboardTOC", action.payload);
            state.dashboardTOC = action.payload;
        },
        setDbPageVer: (state, action) => {
            setItem("DbPageVer", action.payload);
            state.DbPageVer = action.payload;
        },
        setShowDbStatusBar: (state, action) => {
            setItem("showDbStatusBar", action.payload);
            state.showDbStatusBar = action.payload;
        },
        setSkipLinksPage: (state, action) => {
            state.skipLinksPage = action.payload;
        },
        setDbSettingsDnD: (state, action) => {
            setItem("dbSettingsDnD", action.payload);
            state.dbSettingsDnD = action.payload;
        },
    },
});

export const {
    setFetched,
    setLeftDrawerOpened,
    setLeftDrawerWidth,
    setExpandedAreas,
    setAskDeleteFromCart,
    setTopMenu,
    setAppFontSize,
    setFullTopMenu,
    setAppFilters,
    setScroll,
    setAdvancedJT,
    setAdvancedStoryMaps,
    setIs1920,
    setShowToolbox,
    setShowExperimentalShare,
    setShowIDs,
    setSplitLines,
    setSplitLinesExtra,
    setShowAbortDB,
    setPaletteScheme,
    setCustomizeChart,
    setShowGotoDB,
    setDbPositionsTree,
    setShowDbPositionsTreeSwitch,
    setDashboardTOC,
    setDbPageVer,
    setShowDbStatusBar,
    setSkipLinksPage,
    setDbSettingsDnD,
} = CommonSlice.actions;

export const getFetched = (state) => state.Common._fetched;
export const selectLeftDrawerOpened = (state) => state.Common.leftDrawerOpened;
export const selectLeftDrawerWidth = (state) => state.Common.leftDrawerWidth;
export const selectMaxWidth = (state) => state.Common.maxWidth;
export const selectExpandedAreas = (state) => state.Common.expandedAreas;
export const selectAskDeleteFromCart = (state) => state.Common.askDeleteFromCart;
export const getTopMenu = (state) => state.Common.topMenu;
export const getAppFontSize = (state) => state.Common.appFontSize;
export const getFullTopMenu = (state) => state.Common.fullTopMenu;
export const getAppFilters = (state) => state.Common.appFilters;
export const getScroll = (state) => state.Common.scroll;
export const getAdvancedJT = (state) => state.Common.advancedJT;
export const getAdvancedStoryMaps = (state) => state.Common.advancedStoryMaps;
export const getIs1920 = (state) => state.Common.is1920;
export const getShowToolbox = (state) => state.Common.showToolbox;
export const getShowExperimentalShare = (state) => state.Common.showExperimentalShare;
export const getShowIDs = (state) => state.Common.showIDs;
export const getSplitLines = (state) => state.Common.splitLines;
export const getSplitLinesExtra = (state) => state.Common.splitLinesExtra;
export const getShowAbortDB = (state) => state.Common.showAbortDB;
export const getPaletteScheme = (state) => state.Common.paletteScheme;
export const getCustomizeChart = (state) => state.Common.customizeChart;
export const getShowGotoDB = (state) => state.Common.showGotoDB;
export const getDbPositionsTree = (state) => state.Common.dbPositionsTree;
export const getShowDbPositionsTreeSwitch = (state) => state.Common.showDbPositionsTreeSwitch;
export const getDashboardTOC = (state) => state.Common.dashboardTOC;
export const getDbPageVer = (state) => state.Common.DbPageVer;
export const getShowDbStatusBar = (state) => state.Common.showDbStatusBar;
export const getSkipLinksPage = (state) => state.Common.skipLinksPage;
export const getDbSettingsDnD = (state) => state.Common.dbSettingsDnD;

export default CommonSlice.reducer;

export const fetchCommonFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        // getItem("TopMenu")
        //     .then((val) => dispatch(setTopMenu(val || [])))
        //     .catch((e) => console.log(e));

        const { location: l } = window;
        // const _showCatalog =
        //     process.env.NODE_ENV === "development" ||
        //     l?.hostname === "localhost" ||
        //     l?.host === "dbw.stat.intra:82" ||
        //     l?.host === "dbw.stat.intra";
        const _dbSettingsDnD =
            process.env.NODE_ENV === "development" ||
            l?.hostname === "localhost" ||
            l?.host === "dbw.stat.intra:82" ||
            l?.host === "dbw.stat.intra";

        getItem("AppFontSize")
            .then((val) => dispatch(setAppFontSize(val || 18)))
            .catch((e) => console.log(e));

        getItem("fullTM")
            .then((val) =>
                dispatch(
                    setFullTopMenu(
                        val || {
                            showLogin: false,
                            showAppFontSize: false,
                            showLangSwitch: true,
                            showCatalog: true, // _showCatalog,
                        }
                    )
                )
            )
            .catch((e) => console.log(e));

        getItem("filters")
            .then((val) => dispatch(setAppFilters(val || { invert: false, bw: false })))
            .catch((e) => console.log(e));

        getItem("advJT")
            .then((val) => dispatch(setAdvancedJT(val || false)))
            .catch((e) => console.log(e));

        getItem("advStM")
            .then((val) => dispatch(setAdvancedStoryMaps(val || false)))
            .catch((e) => console.log(e));

        getItem("expS")
            .then((val) => dispatch(setShowExperimentalShare(val || false)))
            .catch((e) => console.log(e));

        getItem("showIDs")
            .then((val) => dispatch(setShowIDs(val || false)))
            .catch((e) => console.log(e));

        getItem("splitLines")
            .then((val) => dispatch(setSplitLines(val || true)))
            .catch((e) => console.log(e));

        getItem("splitLinesExtra")
            .then((val) => dispatch(setSplitLinesExtra(val || true)))
            .catch((e) => console.log(e));

        getItem("showAbortDB")
            .then((val) => dispatch(setShowAbortDB(val || true)))
            .catch((e) => console.log(e));

        getItem("paletteScheme")
            .then((val) => dispatch(setPaletteScheme(val || "mono")))
            .catch((e) => console.log(e));

        getItem("customizeChart")
            .then((val) => dispatch(setCustomizeChart(val || false)))
            .catch((e) => console.log(e));

        getItem("showGototDB")
            .then((val) => dispatch(setShowGotoDB(val || true)))
            .catch((e) => console.log(e));

        getItem("dbPositionsTree")
            .then((val) => dispatch(setDbPositionsTree(val || true)))
            .catch((e) => console.log(e));

        getItem("dbPositionsTreeSwitch")
            .then((val) => dispatch(setShowDbPositionsTreeSwitch(val || false)))
            .catch((e) => console.log(e));

        getItem("dashboardTOC")
            .then((val) => dispatch(setDashboardTOC(val || false)))
            .catch((e) => console.log(e));

        const _dbPageVer = 2;
        // process.env.NODE_ENV === "development" || l?.hostname === "localhost" || l?.host === "dbw.stat.intra:82"
        //     ? 2
        //     : 1;

        getItem("DbPageVer")
            // .then((val) => dispatch(setDbPageVer(val || 1)))
            .then((val) => dispatch(setDbPageVer(val || _dbPageVer)))
            .catch((e) => console.log(e));

        getItem("showDbStatusBar")
            .then((val) => dispatch(setShowDbStatusBar(val || false)))
            .catch((e) => console.log(e));

        getItem("dbSettingsDnD")
            .then((val) => dispatch(setDbSettingsDnD(val || true)))
            .catch((e) => console.log(e));
    }
};
