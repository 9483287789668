import React from "react";

/** MUI */
import makeStyles from "@mui/styles/makeStyles";

/** Redux */
import { useDispatch } from "react-redux";
import { setScroll } from "../../_appStore/Common";

/** UI */
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";

/** matomo */
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
    osScrollbar: {
        height: "100vh",
        "& > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle, & > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle":
            {
                background: "#919090",
            },

        "& > .os-scrollbar.os-scrollbar-vertical": {
            padding: 10,
            width: 24,
            transition: "all 0.125s linear",
            "&:hover": {
                padding: 6,
                cursor: "s-resize",
            },
        },
    },
}));

const App = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { enableLinkTracking } = useMatomo();
    enableLinkTracking();

    return (
        <OverlayScrollbarsComponent
            element="span"
            options={{
                scrollbars: {
                    autoHide: "move",
                    autoHideDelay: 666,
                },
            }}
            events={{
                scroll: (a, event) => {
                    dispatch(setScroll(event.target?.scrollTop));
                },
                initialized: (a, b, c) => {
                    dispatch(setScroll(0));
                },
            }}
            defer
            className={classes.osScrollbar}
            id="appScroll"
        >
            {props.children}
        </OverlayScrollbarsComponent>
    );
};

export default App;
