import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_DB";

export const DB_FiltersTableSlice = createSlice({
    name: "DB_FiltersTable",
    initialState: {
        sections: null,
        currentSection: null,
        multiSections: { enabled: false },
        infoTypes: null,
        dimensions: null,
        defaults: null,
        expandedPositions: [],

        selectedSection: null,
        selectedTypes: null,
        selectedDimensions: null,
        selectedPositions: null,

        filtersChanged: true,
        allDefaults: null,

        loadedLang:null
    },
    reducers: {
        setSections: (state, action) => {
            typeof window !== "undefined" && setItem("FT.sections", action.payload);
            state.sections = action.payload;
        },
        setCurrentSection: (state, action) => {
            typeof window !== "undefined" && setItem("FT.currentSection", action.payload);
            state.currentSection = action.payload;
        },
        setMultiSections: (state, action) => {
            typeof window !== "undefined" && setItem("FT.multiSections", action.payload);
            state.multiSections = action.payload;
        },
        setInfoTypes: (state, action) => {
            typeof window !== "undefined" && setItem("FT.infoTypes", action.payload);
            state.infoTypes = action.payload;
        },
        setDimensions: (state, action) => {
            typeof window !== "undefined" && setItem("FT.dimensions", action.payload);
            state.dimensions = action.payload;
        },
        setDefaults: (state, action) => {
            typeof window !== "undefined" && setItem("FT.defaults", action.payload);
            state.defaults = action.payload;
        },
        setExpandedPositions: (state, action) => {
            typeof window !== "undefined" && setItem("FT.expandedPositions", action.payload);
            state.expandedPositions = action.payload;
        },

        setSelectedDimensions: (state, action) => {
            typeof window !== "undefined" && setItem("FT.selectedDimensions", action.payload);
            state.selectedDimensions = action.payload;
        },
        setSelectedPositions: (state, action) => {
            typeof window !== "undefined" && setItem("FT.selectedPositions", action.payload);
            state.selectedPositions = action.payload;
        },
        setFiltersChanged: (state, action) => {
            state.filtersChanged = action.payload;
        },
        setAllDefaults: (state, action) => {
            typeof window !== "undefined" && setItem("FT.AllDefaults", action.payload);
            state.allDefaults = action.payload;
        },
        setLoadedLang: (state, action) => {
            typeof window !== "undefined" && setItem("FT.LoadedLang", action.payload);
            state.loadedLang = action.payload;
        },
    },
});

/** Clear all */
export const clear = () => (dispatch, getState) => {
    dispatch(setSections(null));
    dispatch(setCurrentSection(null));
    dispatch(setMultiSections({ enabled: false }));
    dispatch(setInfoTypes(null));
    dispatch(setDimensions(null));
    dispatch(setDefaults(null));
    dispatch(setExpandedPositions([]));

    dispatch(setSelectedDimensions(null));
    dispatch(setSelectedPositions(null));
};

/** Clear others */
export const clearOthers =
    ({ IDs }) =>
    (dispatch, getState) => {
        const _state = getState()?.DB_FiltersTable;
        console.log({ IDs, _state });

        const filteredDimensions = filterObjectByKeys({ OBJ: _state.dimensions, KEYS: IDs });
        const filteredInfoTypes = filterObjectByKeys({ OBJ: _state.infoTypes, KEYS: IDs });
        const filteredMultiSections = {
            ...filterObjectByKeys({ OBJ: _state.multiSections, KEYS: IDs }),
            enabled: _state.multiSections.enabled,
        };
        const filteredSections = filterObjectByKeys({ OBJ: _state.sections, KEYS: IDs });

        console.log({ filteredDimensions, filteredInfoTypes, filteredMultiSections, filteredSections });

        dispatch(setSections(filteredMultiSections));
        dispatch(setMultiSections(filteredMultiSections));
        dispatch(setInfoTypes(filteredInfoTypes));
        dispatch(setDimensions(filteredDimensions));
    };

const filterObjectByKeys = ({ OBJ, KEYS }) => {
    return OBJ
        ? Object.keys(OBJ)
              .filter((key) => KEYS.includes(key))
              .reduce((obj, key) => {
                  console.log({ obj, key });
                  obj[key] = OBJ[key];
                  return obj;
              }, {})
        : OBJ;
};

export const {
    setSections,
    setCurrentSection,
    setMultiSections,
    setInfoTypes,
    setDimensions,
    setDefaults,
    setExpandedPositions,

    setSelectedDimensions,
    setSelectedPositions,
    setFiltersChanged,
    setAllDefaults,
    setLoadedLang
} = DB_FiltersTableSlice.actions;

export const getSections = (state) => state.DB_FiltersTable.sections;
export const getCurrentSection = (state) => state.DB_FiltersTable.currentSection;
export const getMultiSections = (state) => state.DB_FiltersTable.multiSections;
export const getInfoTypes = (state) => state.DB_FiltersTable.infoTypes;
export const getDimensions = (state) => state.DB_FiltersTable.dimensions;
export const getDefaults = (state) => state.DB_FiltersTable.defaults;
export const getExpandedPositions = (state) => state.DB_FiltersTable.expandedPositions;

export const getSelectedDimensions = (state) => state.DB_FiltersTable.selectedDimensions;
export const getSelectedPositions = (state) => state.DB_FiltersTable.selectedPositions;
export const getFiltersChanged = (state) => state.DB_FiltersTable.filtersChanged;
export const getAllDefaults = (state) => state.DB_FiltersTable.allDefaults;
export const getLoadedLang = (state) => state.DB_FiltersTable.loadedLang;

export default DB_FiltersTableSlice.reducer;

export const fetchTableFiltersFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        getItem("FT.sections").then((val) => dispatch(setSections(val || null)));
        getItem("FT.currentSection").then((val) => dispatch(setCurrentSection(val || null)));
        getItem("FT.multiSections").then((val) => dispatch(setMultiSections(val || { enabled: false })));
        getItem("FT.infoTypes").then((val) => dispatch(setInfoTypes(val || null)));
        getItem("FT.dimensions").then((val) => dispatch(setDimensions(val || null)));
        getItem("FT.defaults").then((val) => dispatch(setDefaults(val || null)));
        getItem("FT.expandedPositions").then((val) => dispatch(setExpandedPositions(val || [])));

        getItem("FT.selectedDimensions").then((val) => dispatch(setSelectedDimensions(val || null)));
        getItem("FT.selectedPositions").then((val) => dispatch(setSelectedPositions(val || null)));
        getItem("FT.AllDefaults").then((val) => dispatch(setAllDefaults(val || null)));
        getItem("FT.LoadedLang").then((val) => dispatch(setLoadedLang(val || null)));
    }
};
