import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_CATALOG";

export const CAT_CommonSlice = createSlice({
    name: "CAT_Common",
    initialState: {
        _fetched: false,
        leftDrawerOpen: true,
        leftDrawerWidth: 888,
        expanded: [],
        scrolled: null,
    },
    reducers: {
        setFetched: (state, action) => {
            state._fetched = action.payload;
        },
        setLeftDrawerOpen: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.drawerOpen", action.payload);
            state.leftDrawerOpen = action.payload;
        },
        setLeftDrawerWidth: (state, action) => {
            state.leftDrawerWidth = action.payload;
        },
        setExpanded: (state, action) => {
            typeof window !== "undefined" && setItem("CAT.expanded", action.payload);
            state.expanded = action.payload;
        },
        setScrolled: (state, action) => {
            state.scrolled = action.payload;
        },
    },
});

export const { setFetched, setLeftDrawerOpen, setLeftDrawerWidth, setExpanded, setScrolled } = CAT_CommonSlice.actions;

export const getFetched = (state) => state.CAT_Common._fetched;
export const getLeftDrawerOpen = (state) => state.CAT_Common.leftDrawerOpen;
export const getLeftDrawerWidth = (state) => state.CAT_Common.leftDrawerWidth;
export const getExpanded = (state) => state.CAT_Common.expanded;
export const getScrolled = (state) => state.CAT_Common.scrolled;

export default CAT_CommonSlice.reducer;

export const fetchCATCommonFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        // getItem("CAT.drawerOpen").then((val) => dispatch(setLeftDrawerOpen(val || false)));
        // getItem("CAT.drawerOpen").then((val) => dispatch(setLeftDrawerOpen(val || true)));
        getItem("CAT.expanded").then((val) => dispatch(setExpanded(val || [])));
    }
};
