import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "./utils/_idb_COMMON";

export const CalendarSlice = createSlice({
    name: "Calendar",
    initialState: {
        exportLimits:{ics:50,xlsx:50}
    },
    reducers: {
        setExportLimits: (state, action) => {
            state.exportLimits = action.payload;
        },
    },
});

export const { setExportLimits } = CalendarSlice.actions;

export const getExportLimits = (state) => state.Calendar.exportLimits;

export default CalendarSlice.reducer;

export const fetchCalendarFromIndexedDB = () => async (dispatch) => {
    if (typeof window !== "undefined") {
        // getItem("TopMenu").then((val) => dispatch(setTopMenu(val || []))).catch(e=>console.log(e));
    }
};