import { configureStore } from "@reduxjs/toolkit";

import AreasReducer from "./Areas";
import CommonReducer from "./Common";
import CalendarReducer from "./Calendar";
import DatesReducer from "./DB_Dates";
import DBCommonReducer from "./DB_Common";
import DBFiltersChartsReducer from "./DB_Filters_Charts";
import DBFiltersTableReducer from "./DB_Filters_Table";
import DBIndicatorsReducer from "./DB_Indicators";
import DBListsTableReducer from "./DB_Lists_Table";
import DBSettingsTableReducer from "./DB_Settings_Table";
import DBSettingsChartBarsReducer from "./DB_Settings_Chart_Bars";
import StorytellingReducer from "./Storytelling";
import TerritorialUnitsReducer from "./DB_JTs";
import VALCommonReducer from "./VAL_Common";
import CATCommonReducer from "./CAT_Common";
import CATCalculatorsReducer from "./CAT_Calculators";
import CATAggregatesReducer from "./CAT_Aggregates";

export default configureStore({
    reducer: {
        Areas: AreasReducer,
        Calendar: CalendarReducer,
        Common: CommonReducer,
        DB_Common: DBCommonReducer,
        DB_Dates: DatesReducer,
        DB_FiltersCharts: DBFiltersChartsReducer,
        DB_FiltersTable: DBFiltersTableReducer,
        DB_Indicators: DBIndicatorsReducer,
        DB_JTs: TerritorialUnitsReducer,
        DB_ListsTable: DBListsTableReducer,
        DB_Settings_Chart_Bars: DBSettingsChartBarsReducer,
        DB_SettingsTable: DBSettingsTableReducer,
        Storytelling: StorytellingReducer,
        VAL_Common: VALCommonReducer,
        CAT_Common: CATCommonReducer,
        CAT_Calculators: CATCalculatorsReducer,
        CAT_Aggregates: CATAggregatesReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
});
